export const redirects = JSON.parse("{}")

export const routes = Object.fromEntries([
  ["/", { loader: () => import(/* webpackChunkName: "index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/index.html.js"), meta: {"title":""} }],
  ["/docs/faq.html", { loader: () => import(/* webpackChunkName: "docs_faq.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/faq.html.js"), meta: {"title":"Frequently Asked Questions"} }],
  ["/docs/fwscript.html", { loader: () => import(/* webpackChunkName: "docs_fwscript.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/fwscript.html.js"), meta: {"title":"Firmware Utility Script"} }],
  ["/docs/getting-started.html", { loader: () => import(/* webpackChunkName: "docs_getting-started.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/getting-started.html.js"), meta: {"title":"Getting Started"} }],
  ["/docs/known-issues.html", { loader: () => import(/* webpackChunkName: "docs_known-issues.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/known-issues.html.js"), meta: {"title":"Known Issues"} }],
  ["/docs/news.html", { loader: () => import(/* webpackChunkName: "docs_news.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/news.html.js"), meta: {"title":"Latest Updates"} }],
  ["/docs/supported-devices.html", { loader: () => import(/* webpackChunkName: "docs_supported-devices.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/supported-devices.html.js"), meta: {"title":"Supported Devices"} }],
  ["/supported-devices/template.html", { loader: () => import(/* webpackChunkName: "supported-devices_template.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/supported-devices/template.html.js"), meta: {"title":"Supported Devices"} }],
  ["/docs/boot-modes/developer.html", { loader: () => import(/* webpackChunkName: "docs_boot-modes_developer.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/boot-modes/developer.html.js"), meta: {"title":"Developer Mode"} }],
  ["/docs/boot-modes/", { loader: () => import(/* webpackChunkName: "docs_boot-modes_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/boot-modes/index.html.js"), meta: {"title":"ChromeOS Boot Modes"} }],
  ["/docs/boot-modes/legacy.html", { loader: () => import(/* webpackChunkName: "docs_boot-modes_legacy.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/boot-modes/legacy.html.js"), meta: {"title":"Legacy Boot Mode / Alternative Bootloader (AltFw)"} }],
  ["/docs/boot-modes/normal.html", { loader: () => import(/* webpackChunkName: "docs_boot-modes_normal.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/boot-modes/normal.html.js"), meta: {"title":"Normal/Verified Boot Mode"} }],
  ["/docs/boot-modes/recovery.html", { loader: () => import(/* webpackChunkName: "docs_boot-modes_recovery.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/boot-modes/recovery.html.js"), meta: {"title":"Recovery Mode"} }],
  ["/docs/firmware/booting.html", { loader: () => import(/* webpackChunkName: "docs_firmware_booting.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/booting.html.js"), meta: {"title":"Booting Your OS"} }],
  ["/docs/firmware/flashing-firmware.html", { loader: () => import(/* webpackChunkName: "docs_firmware_flashing-firmware.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/flashing-firmware.html.js"), meta: {"title":"Flashing MrChromebox Custom Firmware"} }],
  ["/docs/firmware/", { loader: () => import(/* webpackChunkName: "docs_firmware_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/index.html.js"), meta: {"title":"ChromeOS Device Firmware"} }],
  ["/docs/firmware/manual-flashing.html", { loader: () => import(/* webpackChunkName: "docs_firmware_manual-flashing.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/manual-flashing.html.js"), meta: {"title":"Manually Flashing Firmware"} }],
  ["/docs/firmware/types.html", { loader: () => import(/* webpackChunkName: "docs_firmware_types.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/types.html.js"), meta: {"title":"Firmware Update Types"} }],
  ["/docs/firmware/updating-firmware.html", { loader: () => import(/* webpackChunkName: "docs_firmware_updating-firmware.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/updating-firmware.html.js"), meta: {"title":"Updating MrChromebox Custom Firmware"} }],
  ["/docs/reverting/bootableusb.html", { loader: () => import(/* webpackChunkName: "docs_reverting_bootableusb.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/reverting/bootableusb.html.js"), meta: {"title":"Making a Bootable USB"} }],
  ["/docs/reverting/booting-recovery-usb.html", { loader: () => import(/* webpackChunkName: "docs_reverting_booting-recovery-usb.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/reverting/booting-recovery-usb.html.js"), meta: {"title":"Booting the Recovery USB"} }],
  ["/docs/reverting/flashing-stock.html", { loader: () => import(/* webpackChunkName: "docs_reverting_flashing-stock.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/reverting/flashing-stock.html.js"), meta: {"title":"Restoring the Stock ChromeOS Firmware"} }],
  ["/docs/reverting/", { loader: () => import(/* webpackChunkName: "docs_reverting_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/reverting/index.html.js"), meta: {"title":"Reverting to Stock ChromeOS"} }],
  ["/docs/reverting/making-recovery-usb.html", { loader: () => import(/* webpackChunkName: "docs_reverting_making-recovery-usb.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/reverting/making-recovery-usb.html.js"), meta: {"title":"Making a Recovery USB"} }],
  ["/docs/support/compiling.html", { loader: () => import(/* webpackChunkName: "docs_support_compiling.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/compiling.html.js"), meta: {"title":"Compiling Your Own Firmware"} }],
  ["/docs/support/debugging.html", { loader: () => import(/* webpackChunkName: "docs_support_debugging.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/debugging.html.js"), meta: {"title":"Debugging"} }],
  ["/docs/support/", { loader: () => import(/* webpackChunkName: "docs_support_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/index.html.js"), meta: {"title":"Help & Support"} }],
  ["/docs/firmware/wp/disabling.html", { loader: () => import(/* webpackChunkName: "docs_firmware_wp_disabling.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/wp/disabling.html.js"), meta: {"title":"Disabling Firmware Write Protection"} }],
  ["/docs/firmware/wp/", { loader: () => import(/* webpackChunkName: "docs_firmware_wp_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/firmware/wp/index.html.js"), meta: {"title":"Firmware Write Protection on ChromeOS Devices"} }],
  ["/docs/support/unbricking/", { loader: () => import(/* webpackChunkName: "docs_support_unbricking_index.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/unbricking/index.html.js"), meta: {"title":"Unbricking"} }],
  ["/docs/support/unbricking/unbrick-ch341a.html", { loader: () => import(/* webpackChunkName: "docs_support_unbricking_unbrick-ch341a.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/unbricking/unbrick-ch341a.html.js"), meta: {"title":"Unbricking/Flashing with a ch341a USB Programmer"} }],
  ["/docs/support/unbricking/unbrick-suzyq.html", { loader: () => import(/* webpackChunkName: "docs_support_unbricking_unbrick-suzyq.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/docs/support/unbricking/unbrick-suzyq.html.js"), meta: {"title":"Unbricking/Flashing with a SuzyQable"} }],
  ["/404.html", { loader: () => import(/* webpackChunkName: "404.html" */"/home/runner/work/website/website/.vuepress/.temp/pages/404.html.js"), meta: {"title":""} }],
]);
